<template>
  <form>
    <b-form-select
      v-model="instructorKey"
      :options="instructorOptions"
      :plain="true"
      @change="handleSelectChange"
      class="quick-jump mr-0 mr-sm-2 mb-3 mb-sm-0"
    >
      <template slot="first">
        <option :value="null" disabled>{{ i18n['quick-jump-instructor'].tcSelectAnotherInstructor }}</option>
      </template>
    </b-form-select>
  </form>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'quick-jump-instructor',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'quick-jump-instructor': {
            tcSelectAnotherInstructor: 'Select another instructor'
          }
        }
      }
    },
    instructorKeyProp: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      instructorKey: this.instructorKeyProp
    }
  },
  computed: {
    ...mapGetters({
      instructors: 'conversations/instructors',
    }),
    instructorOptions() {
      return this.instructors.map((el) => {
        return {
          text: el.name,
          value: el.ind_key
        }
      })
    },
  },
  methods: {
    ...mapActions({
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
    }),
    async handleSelectChange() {
      await Promise.all([await this.setSelectedIndividualKey(this.instructorKey)]).then(
        () => {
          this.$emit('instructor_changed')
        }
      )
    }
  }
}
</script>

<style/>